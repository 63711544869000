<template>
  <b-form @submit.prevent="save">
    <b-container fluid>
      <b-row>
        <b-col>
          <!-- password input -->
          <b-form-group
              id="password-form-group"
              :label="$t('USER_PASSWORD_LABEL') | capitalize"
              label-for="password-input"
              :state="state('password')"
          >
            <b-form-input id="password-input" type="password" v-model="$v.form.password.$model" :state="state('password')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="password-input-feedback">
              <div v-for="error in errors('password')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_PASSWORD_LABEL')},}) | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <!-- verification input -->
          <b-form-group
              id="verification-form-group"
              :label="$t('USER_PASSWORD_VERIFICATION_LABEL') | capitalize"
              label-for="verification-input"
              :state="state('verification')"
          >
            <b-form-input id="verification-input" type="password" v-model="$v.form.verification.$model" :state="state('verification')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="verification-input-feedback">
              <div v-for="error in errors('verification')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_PASSWORD_VERIFICATION_LABEL'), field2: $t('USER_PASSWORD_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button type="submit" variant="primary">
            <b-icon-circle-fill animation="throb" v-if="isBusy"></b-icon-circle-fill>
            <span v-if="isBusy" class="m-2">{{ $t('FORMS_SAVING_LABEL') | capitalize}}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL') | capitalize}}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import {Form} from "@/mixins";
import {required, sameAs} from "vuelidate/lib/validators";
import Users from "@/users";

export default {
  mixins: [Form],
  props: ['userId'],
  name: "UserChangePassword",
  data() {
    return {
      form: {
        password: '',
        verification: '',
      }
    }
  },
  methods: {
    async save() {
      try {
        this.$v.form.$touch();
        if(this.$v.form.$invalid) {
          return;
        }
        this.isBusy = true;
        await Users.resetPassword(this.userId, this.form);
        this.$emit('password-changed');
      } catch (e) {
        this.sendError('USER_ERROR_CHANGING_PASSWORD', this.form);
      } finally {
        this.isBusy = false;
      }
    }
  },
  validations: {
    form: {
      password: {required},
      verification: {required, sameAs: sameAs('password')}
    }
  }
}
</script>

<style scoped>

</style>
